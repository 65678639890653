import { translate } from '@/domains/core/localization/translate';
export const {
  title,
  subTitle,
  text,
  button
} = translate({
  "title": "Error 404",
  "subTitle": "Oops, that's not supposed to happen",
  "text": "Follow us, we'll take you somewhere else",
  "button": "Return to homepage"
});